import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private _tenant$: BehaviorSubject<any> = new BehaviorSubject(null);
  tenant = this._tenant$.asObservable().pipe(distinctUntilChanged());

  private _countries$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  countries = this._countries$.asObservable().pipe(distinctUntilChanged());

  private _cities$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  cities = this._cities$.asObservable().pipe(distinctUntilChanged());

  private _calcMethods$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  calcMethods = this._calcMethods$.asObservable().pipe(distinctUntilChanged());

  constructor() {}

  setTenant(tenant: any): void {
    this._tenant$.next(tenant);
  }

  setCountries(countries: any): void {
    this._countries$.next(countries);
  }

  setCities(cities: any): void {
    this._cities$.next(cities);
  }

  setCalcMethods(calcMethods: any): void {
    this._calcMethods$.next(calcMethods);
  }
}
