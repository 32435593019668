<nav class="mobile-bottom-nav">
  <div class="mobile-bottom-nav__item" [class.mobile-bottom-nav__item--active]="isActiveItem('profile')"
    (click)="onNavigateToProfile()">
    <div class="mobile-bottom-nav__item-content">
      <i class="material-icons">person</i>
      Profile
    </div>
  </div>
  <div class="mobile-bottom-nav__item" [class.mobile-bottom-nav__item--active]="isActiveItem('prayer')"
    (click)="onNavigateToPrayerTime()">
    <div class="mobile-bottom-nav__item-content">
      <i class="material-icons">timer</i>
      Prayer Time
    </div>
  </div>
  <div class="mobile-bottom-nav__item" (click)="onSignOut()">
    <div class="mobile-bottom-nav__item-content">
      <i class="material-icons">exit_to_app</i>
      Sign Out
    </div>
  </div>
</nav>