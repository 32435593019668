import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEY } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  constructor() {}

  getToken(): any {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN));
  }

  saveToken(token: any): void {
    localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, JSON.stringify(token));
  }

  destroyToken(): void {
    localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
  }
}
