import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LOCAL_STORAGE_KEY } from 'src/app/types';

import { ApiService } from './api.service';
import { StateService } from './state.service';
import { JwtService } from './jwt.service';
import { PersistenceService } from './persistence.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly _api: ApiService,
    private readonly _store: StateService,
    private readonly _jwt: JwtService,
    private readonly _persistenceService: PersistenceService
  ) {}

  signIn(tenant): Observable<any> {
    return this._api.post('/tenant/login', tenant).pipe(
      map((tenantRes) => tenantRes.body),
      tap((userRes) => {
        if (userRes.message) {
          return;
        }

        this._jwt.saveToken(userRes?.tenantPIN);
        this._persistenceService.setItem(LOCAL_STORAGE_KEY.TENANT, {
          ...userRes,
          tenantPIN: tenant.tenantPIN,
        });
        this._store.setTenant({ ...userRes, tenantPIN: tenant.tenantPIN });
      })
    );
  }

  signUp(tenant): Observable<any> {
    return this._api.post('/tenant', tenant).pipe(
      map((tenantRes) => tenantRes.body),
      tap((user) => {
        this._store.setTenant({ ...user, tenantPIN: tenant.tenantPIN });
        this._jwt.saveToken(user?.tenantPIN);
        this._persistenceService.setItem(LOCAL_STORAGE_KEY.TENANT, {
          ...user,
          tenantPIN: tenant.tenantPIN,
        });
      })
    );
  }
}
