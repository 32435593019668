import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppInitService, JwtService, StateService, PersistenceService } from './core/services';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LOCAL_STORAGE_KEY } from './types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'adhan-prayer';

  private _stop$: Subject<any>;

  constructor(
    private readonly _persistenceService: PersistenceService,
    private readonly _store: StateService,
    private readonly _appInitService: AppInitService
  ) {
    this._setVariables();
  }

  ngOnInit(): void {
    this._populateTenant();
    this._getDefaultData();
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  private _setVariables(): void {
    this._stop$ = new Subject();
  }

  private _getDefaultData(): void {
    forkJoin({
      countries: this._appInitService.getCountries(),
      calcMethods: this._appInitService.getCalcMethods(),
    })
      .pipe(takeUntil(this._stop$))
      .subscribe(() => {});
  }

  private _populateTenant(): void {
    const tenant = this._persistenceService.getItem(LOCAL_STORAGE_KEY.TENANT);

    if (tenant) {
      this._store.setTenant(tenant);
    }
  }
}
