import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StateService } from './state.service';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(private readonly _api: ApiService, private readonly _store: StateService) {}

  getCountries(): Observable<any> {
    return this._api.get('/country').pipe(
      map((countries) => countries.body),
      tap((countries) => {
        this._store.setCountries(countries);
      })
    );
  }

  getCities(): Observable<any> {
    return this._api.get('/city').pipe(
      map((cities) => cities.body),
      tap((cities) => {
        this._store.setCities(cities);
      })
    );
  }

  getCitiesByCountry(countryId): Observable<any> {
    return this._api.get(`/city/bycountry/${countryId}`).pipe(
      map((cities) => cities.body),
      tap((cities) => {
        this._store.setCities(cities);
      })
    );
  }

  getCalcMethods(): Observable<any> {
    return this._api.get('/calculationmethod/findall').pipe(
      map((calcMethods) => calcMethods.body),
      tap((calcMethods) => {
        this._store.setCalcMethods(calcMethods);
      })
    );
  }
}
