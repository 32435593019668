import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService, PersistenceService } from 'src/app/core/services';
import { LOCAL_STORAGE_KEY } from 'src/app/types';

export interface IFootItem {
  name: string;
  path: string;
  icon: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(
    private readonly _router: Router,
    private readonly _jwt: JwtService,
    private readonly _persistenceService: PersistenceService
  ) {}

  ngOnInit(): void {}

  onNavigateToProfile(): void {
    this._router.navigateByUrl('/profile');
  }

  onNavigateToPrayerTime(): void {
    this._router.navigateByUrl('/prayer-time');
  }

  onSignOut(): void {
    this._jwt.destroyToken();
    this._persistenceService.removeItem(LOCAL_STORAGE_KEY.TENANT);
    this._router.navigateByUrl('/sign-in');
  }

  isActiveItem(path: string): boolean {
    return this._router.url.toLowerCase().includes(path.toLowerCase());
  }
}
