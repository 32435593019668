import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _baseUrl: string;

  constructor(private readonly _http: HttpClient) {
    this._baseUrl = environment.api;
  }

  private _formatErrors(error: any) {
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this._http
      .get(`${this._baseUrl}${path}`, {
        params,
        observe: 'response',
      })
      .pipe(retry(1), catchError(this._formatErrors));
  }

  put(path: string, body): Observable<any> {
    return this._http.put(`${this._baseUrl}${path}`, body).pipe(catchError(this._formatErrors));
  }

  patch(path: string, body): Observable<any> {
    return this._http.patch(`${this._baseUrl}${path}`, body).pipe(catchError(this._formatErrors));
  }

  post(path: string, body = {}): Observable<any> {
    return this._http
      .post(`${this._baseUrl}${path}`, body, {
        observe: 'response',
      })
      .pipe(catchError(this._formatErrors));
  }

  delete(path): Observable<any> {
    return this._http.delete(`${this._baseUrl}${path}`).pipe(catchError(this._formatErrors));
  }
}
