import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthGuard, NoAuthGuard } from './guards';
import { HttpTokenInterceptor } from './interceptors';
import {
  ApiService,
  JwtService,
  StateService,
  PersistenceService,
  AuthService,
  AppInitService,
} from './services';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    JwtService,
    AuthService,
    StateService,
    PersistenceService,
    AppInitService,
    AuthGuard,
    NoAuthGuard,
  ],
})
export class CoreModule {}
